import React from 'react';
import Illustration from '@domain-group/fe-co-brand-illustrations';
import * as styles from './speak-to-agent.style';
import ContentContainer from '../content-container/content-container';

export const APPRAISAL_ENTRY_POINT = 'Sell Landing Page';

const findAnAgentCard = ({
  baseUrl = 'https://www.domain.com.au',
  trackFindLocalAgent,
}: {
  baseUrl?: string;
  trackFindLocalAgent: () => void;
}) => (
  <a
    css={styles.cardContainer}
    data-testid="find-agents-link"
    href={`${baseUrl}/real-estate-agents/`}
    onClick={trackFindLocalAgent}
    onAuxClick={trackFindLocalAgent}
  >
    <Illustration type="spot" shape="D" name="userAccount" size="regular" />
    <div css={styles.textContainer}>
      <h2>Find an agent</h2>
      <p>
        Don’t go it alone—start your selling journey with a local agent you can
        trust.
      </p>
    </div>
  </a>
);

const getAnAppraisalCard = ({
  baseUrl = 'https://www.domain.com.au',
  trackAppraisalFunnel,
}: {
  baseUrl?: string;
  trackAppraisalFunnel: () => void;
}) => (
  <a
    css={styles.cardContainer}
    data-testid="get-appraisal-link"
    href={`${baseUrl}/services/appraisal?entry-point=${encodeURI(
      APPRAISAL_ENTRY_POINT,
    )}&exitUrl=${encodeURIComponent('/sell')}`}
    onClick={trackAppraisalFunnel}
    onAuxClick={trackAppraisalFunnel}
  >
    <Illustration
      type="spot"
      shape="circle"
      name="propertyReport"
      size="regular"
    />
    <div css={styles.textContainer}>
      <h2>Get an appraisal</h2>
      <p>
        Find out how much your home is worth by requesting an appraisal from a
        local expert.
      </p>
    </div>
  </a>
);

const SpeakToAgent = ({
  baseUrl = 'https://www.domain.com.au',
  trackFindLocalAgent,
  trackAppraisalFunnel,
}: {
  baseUrl?: string;
  trackFindLocalAgent: () => void;
  trackAppraisalFunnel: () => void;
}): JSX.Element => (
  <div css={styles.container}>
    <div css={styles.contentWrapper}>
      <h2>Speak to your agent about listing on Domain today!</h2>
      <h3>Don’t have an agent? We can help.</h3>
      <ContentContainer
        overrideStyles={styles.gaps}
        first={findAnAgentCard({ baseUrl, trackFindLocalAgent })}
        second={getAnAppraisalCard({ baseUrl, trackAppraisalFunnel })}
        changeForDevice="tablet"
      />
    </div>
  </div>
);

export default SpeakToAgent;
