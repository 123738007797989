import React from 'react';

import PaginationDot from './pagination-dot';
import * as styles from './pagination.style';

export type PaginationProps = {
  dots: number;
  index: number;
  onChangeIndex?: (idx: number) => void;
};

const Pagination = ({
  dots,
  index,
  /* istanbul ignore next */
  onChangeIndex = () => undefined,
}: PaginationProps): JSX.Element => {
  const handleClick = (event: React.MouseEvent, idx: number) => {
    onChangeIndex(idx);
  };

  const children: JSX.Element[] = [];

  for (let dot = 0; dot < dots; dot += 1) {
    children.push(
      <li css={styles.paginationItem} key={dot}>
        <PaginationDot
          index={dot}
          active={dot === index}
          onClick={handleClick}
        />
      </li>,
    );
  }

  return (
    <ul css={styles.pagination} data-testid="sold-stats__pagination">
      {children}
    </ul>
  );
};

export default Pagination;
