import numeral from 'numeral';

const stripUnwantedCharacters = (number: string) =>
  number.replace(/^\+61/, '').replace(/[^0-9]/g, '');

export const formatTelephoneNumber = (ausNumberWithCountryCode: string) => {
  const ausNumber = stripUnwantedCharacters(ausNumberWithCountryCode);
  // https://en.wikipedia.org/wiki/Telephone_numbers_in_Australia omitting the braces (0x) to
  // match profile listings format
  if (ausNumber.startsWith('1300') || ausNumber.startsWith('1800')) {
    return `${ausNumber.slice(0, 4)} ${ausNumber.slice(4, 7)} ${ausNumber.slice(
      7,
    )}`;
  }
  if (ausNumber.startsWith('13') && ausNumber.length === 6) {
    return `${ausNumber.slice(0, 2)} ${ausNumber.slice(2)}`;
  }
  if (ausNumber.startsWith('4') || ausNumber.startsWith('5')) {
    return `0${ausNumber.slice(0, 3)} ${ausNumber.slice(
      3,
      6,
    )} ${ausNumber.slice(6)}`;
  }
  return `0${ausNumber.slice(0, 1)} ${ausNumber.slice(1, 5)} ${ausNumber.slice(
    5,
  )}`;
};

export const formatCurrency = (number: number) => {
  if (number < 999500) {
    return numeral(number).format('$0a');
  }
  if (number >= 999500 && number < 1000000) {
    return '$1.0m';
  }

  return numeral(number).format('$0.0a');
};
