import React, { ReactNode } from 'react';

import * as styles from './layout.styles';

interface OneColumnLayoutProps {
  children?: ReactNode;
  marginBottom?: 'none' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
}

const OneColumnLayout = ({
  children,
  marginBottom = 'none',
}: OneColumnLayoutProps): JSX.Element => (
  <div css={styles.oneColumnWrapper(marginBottom)}>{children}</div>
);

export default OneColumnLayout;
