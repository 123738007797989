import React from 'react';
import { Avatar } from '@domain-group/fe-blueprint-ui/avatar';

export type AvatarWithFallbackProps = {
  imageUrl?: string;
  imageTitle?: string;
  className?: string;
  shape?: 'square' | 'circle';
  size?: 'small' | 'medium' | 'large' | 'custom';
  colorScheme?: 'dark' | 'light';
  isBordered?: boolean;
};

const AvatarWithFallback = ({
  imageUrl = undefined,
  ...props
}: AvatarWithFallbackProps): JSX.Element | null => {
  // Strip low resolution fallback avatars from Agency API
  const url = imageUrl?.includes('noAgentPhoto') ? undefined : imageUrl;

  return <Avatar imageUrl={url} {...props} />;
};

export default AvatarWithFallback;
