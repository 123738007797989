import React, { useContext } from 'react';

import { srOnly } from '@domain-group/fe-brary';
import { LinkButton } from '@domain-group/fe-blueprint-ui/button';

import { buildAppraisalUrl } from '../../../../utils';
import ServerInfoContext from '../../../../contexts/server-info';
import { CallButton, EmailButton } from '../../../shared';

import * as styles from '../../../../styles/contact-buttons.style';

export type Props = {
  isVertical: boolean;
  contactId: number;
  name: string;
  hasEmail?: boolean | null;
  telephone?: string | null;
  agencyName: string;
  onOpenModal: () => void;
  onCall: ({ contactId }: { contactId: number }) => void;
  onCallReveal: ({ contactId }: { contactId: number }) => void;
  onGetAppraisal: ({ contactId }: { contactId: number }) => void;
  isPropertyManager?: boolean;
  hideAppraisalCta?: boolean;
  appraisalEntryPoint?: string;
};

const ContactButtons = ({
  isVertical,
  contactId,
  name,
  hasEmail,
  telephone,
  agencyName,
  onOpenModal,
  onCall = () => undefined,
  onCallReveal = () => undefined,
  onGetAppraisal = () => undefined,
  isPropertyManager = false,
  hideAppraisalCta = false,
  appraisalEntryPoint = 'Agent Profile Card',
}: Props): JSX.Element => {
  // isNarrow represents cards where the contact button area can be smaller/narrower
  const isNarrow = isPropertyManager || hideAppraisalCta;

  const serverInfo = useContext(ServerInfoContext);
  const { baseUrl } = serverInfo;
  const href = buildAppraisalUrl({
    contactId,
    baseUrl,
    entryPoint: appraisalEntryPoint,
  });

  return (
    <div css={styles.wrapper(isVertical, isNarrow)}>
      <h4 css={srOnly}>Contact Agent</h4>
      <div css={styles.buttonGroup(isVertical, isNarrow)}>
        {hasEmail ? (
          <EmailButton
            stretched
            size="small"
            onClick={onOpenModal}
            name={`${name} of ${agencyName}`}
          />
        ) : null}
        {telephone ? (
          <CallButton
            stretched
            size="small"
            appearance="outline"
            phoneNumber={telephone}
            name={name}
            onCall={() => onCall({ contactId })}
            onCallReveal={() => onCallReveal({ contactId })}
          />
        ) : null}
        {isPropertyManager || hideAppraisalCta ? null : (
          <LinkButton
            size="small"
            appearance="muted"
            data-testid="cta-appraisal-link"
            css={styles.button(true)}
            href={href}
            onClick={() => onGetAppraisal({ contactId })}
          >
            Request a free appraisal <span css={srOnly}>from {name}</span>
          </LinkButton>
        )}
      </div>
    </div>
  );
};

export default ContactButtons;
