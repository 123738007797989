import React from 'react';
import { LinkButton } from '@domain-group/fe-blueprint-ui/button';
import { ArrowRightIconSmall } from '@domain-group/fe-co-icon/lib/js/arrow-right';

const Link = ({
  children,
  href,
  onClick,
}: {
  children: any;
  href?: string;
  onClick?: () => void;
}): JSX.Element => (
  <LinkButton
    appearance="link"
    icon={<ArrowRightIconSmall />}
    iconPosition="right"
    href={href || '#'}
    onClick={onClick}
  >
    {children}
  </LinkButton>
);

export default Link;
