import React from 'react';
import { useFragment, gql } from '@apollo/client';
import Link from 'next/link';
import { ContactSalesStatisticsStoryFragment } from '../../generated/graphql';
import { arrayFilterNotEmpty } from '../../utils';
import { Intro, SummarySales, SummarySegment, SummaryTop } from './components';

interface Contact {
  name?: string | null;
  agentIdV2?: string | null;
  profileUrl?: string | null;
}

export type Props = {
  agentIdV2: string;
  agencyId: number;
};

const TYPE_NAME = 'Contact';
const FRAGMENT_NAME = 'ContactSalesStatisticsStory';
const FRAGMENT = gql`
  fragment ContactSalesStatisticsStory on Contact {
    id
    agentIdV2
    agencyId
    name
    agencyName
    agencyUrlPath
    isHideSoldLeasedListings

    suburbStatistics {
      averageDaysOnMarket
      averageSoldPrice
      totalSaleAmount
      total

      salesType {
        sold
        auction
      }
      listingType {
        apartment
        house
        land
        rural
      }
      topListing {
        soldPrice
        daysOnMarket
        soldDate
        displayableAddress
        bedrooms
        listingType
        salesType
        state
        postcode
        adId
      }
      coWorkerContacts {
        id
        isActiveProfilePage
      }
      coWorkerListingCount
    }

    activeSuburbs {
      suburbName
      suburbSlug
      listingCount
    }

    agencyByAgencyId {
      id
      agencyId
      showTabSoldLastYear

      contacts {
        name
        agentIdV2
        profileUrl
      }
    }
  }
`;

const ContactSalesStatisticsStoryWithFragment = ({
  agentIdV2,
  agencyId,
}: Props): JSX.Element | null => {
  const { complete, data: contact } =
    useFragment<ContactSalesStatisticsStoryFragment>({
      from: {
        __typename: TYPE_NAME,
        id: `${agencyId}-${agentIdV2}`,
      },
      fragment: ContactSalesStatisticsStoryWithFragment.fragment,
      fragmentName: ContactSalesStatisticsStoryWithFragment.fragmentName,
    });

  // Hide if chosen by agent/agency or no sold properties
  if (
    !complete ||
    !contact ||
    (contact?.isHideSoldLeasedListings &&
      !contact?.agencyByAgencyId?.showTabSoldLastYear) ||
    contact?.suburbStatistics?.total === 0
  ) {
    return null;
  }

  const agencyAgents = (contact?.agencyByAgencyId?.contacts || []).filter(
    arrayFilterNotEmpty,
  );

  const coworkersArray = (contact?.suburbStatistics?.coWorkerContacts || [])
    .filter(arrayFilterNotEmpty)
    .filter((agent) => agent?.isActiveProfilePage)
    .reduce((arr: Contact[], agent) => {
      const match = agencyAgents.filter(
        (item) => item?.agentIdV2 === agent?.id,
      );

      return [...arr, ...match];
    }, [])
    .map((agent, index, array) => (
      <span key={agent.profileUrl}>
        <Link
          href={(agent.profileUrl || '').split('.au')[1]}
          key={agent.profileUrl}
        >
          {agent.name}
        </Link>
        {index < array.length - 2 && ', '}
        {index === array.length - 2 && ' and '}
      </span>
    ));

  return (
    <div data-testid="sales-statistics-story">
      <Intro name={contact?.name || ''}>
        <SummarySales
          name={contact?.name || ''}
          isAgent
          agencyName={contact?.agencyName || ''}
          agencyUrl={`/real-estate-agencies/${contact?.agencyUrlPath || ''}`}
          totalSoldAndAuctioned={contact?.suburbStatistics?.total || 0}
          averageSoldPrice={contact?.suburbStatistics?.averageSoldPrice || 0}
          totalSoldPrice={contact?.suburbStatistics?.totalSaleAmount || 0}
        >
          {coworkersArray?.length ? (
            <>
              {contact?.name} worked with {coworkersArray} on{' '}
              {contact?.suburbStatistics?.coWorkerListingCount} of these sales.
            </>
          ) : null}
        </SummarySales>
        <SummarySegment
          name={contact?.name || ''}
          house={contact?.suburbStatistics?.listingType?.house || 0}
          unit={contact?.suburbStatistics?.listingType?.apartment || 0}
          land={contact?.suburbStatistics?.listingType?.land || 0}
          rural={contact?.suburbStatistics?.listingType?.rural || 0}
          totalSold={contact?.suburbStatistics?.salesType?.sold || 0}
          totalAuctioned={contact?.suburbStatistics?.salesType?.auction || 0}
          averageDaysOnMarket={
            contact?.suburbStatistics?.averageDaysOnMarket || 0
          }
        />
        <SummaryTop
          isAgent
          name={contact?.name || ''}
          soldPrice={contact?.suburbStatistics?.topListing?.soldPrice || 0}
          daysOnMarket={
            contact?.suburbStatistics?.topListing?.daysOnMarket || 0
          }
          displayableAddress={
            contact?.suburbStatistics?.topListing?.displayableAddress || ''
          }
          soldDate={contact?.suburbStatistics?.topListing?.soldDate || ''}
          bedrooms={contact?.suburbStatistics?.topListing?.bedrooms || 0}
          listingType={contact?.suburbStatistics?.topListing?.listingType || ''}
          salesType={contact?.suburbStatistics?.topListing?.salesType || ''}
          state={contact?.suburbStatistics?.topListing?.state || ''}
          postcode={contact?.suburbStatistics?.topListing?.postcode || ''}
          adId={contact?.suburbStatistics?.topListing?.adId || 0}
        />
      </Intro>
    </div>
  );
};

ContactSalesStatisticsStoryWithFragment.fragment = FRAGMENT;
ContactSalesStatisticsStoryWithFragment.fragmentName = FRAGMENT_NAME;

export default ContactSalesStatisticsStoryWithFragment;
