import crypto, { BinaryToTextEncoding } from 'crypto';
import { User, UserDetailsRedacted } from '../@types';

/**
 * Create hash with Node Crypto
 * https://nodejs.org/api/crypto.html#crypto_crypto_createhash_algorithm_options
 */
const hashing = (
  str = '',
  hashMethod = 'md5',
  encodingMethod = 'hex' as BinaryToTextEncoding,
) => crypto.createHash(hashMethod).update(str).digest(encodingMethod);

const getUserDetails = (user?: User): UserDetailsRedacted => {
  if (user?.disableUserTracking) {
    return {
      userId: undefined,
      domainUserId: undefined,
      sessionId: undefined,
      disableUserTracking: true,
      userName: undefined,
      givenName: undefined,
      familyName: undefined,
      postcode: undefined,
      userToken: undefined,
      emailHash: undefined,
      ipHash: undefined,
    };
  }

  return {
    userId: user?.userId,
    domainUserId: user?.domainUserId,
    sessionId: user?.sessionId,
    disableUserTracking: false,
    userName: user?.userName,
    givenName: user?.givenName,
    familyName: user?.familyName,
    postcode: user?.postcode,
    userToken: user?.userToken,
    emailHash: user?.email ? hashing(user?.email) : undefined,
    ipHash: user?.ip ? hashing(user?.ip, 'sha256') : undefined,
  };
};

export default getUserDetails;
