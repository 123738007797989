import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Loader } from '@domain-group/fe-blueprint-ui/loader';
import { Table, Stats } from '..';
import { ISuburbStatistics } from '../../@types';
import * as styles from './data.style';

export type Props = {
  id: string;
  loading: boolean;
  selectedSuburbName?: string | null;
  suburbStatisticsData?: ISuburbStatistics | null;
};

const Data = ({
  id,
  loading,
  selectedSuburbName,
  suburbStatisticsData,
}: Props): JSX.Element | null => {
  const [suburbStatistics, setSuburbStatistics] =
    useState(suburbStatisticsData);
  const [hasError, setHasError] = useState(false);

  // Keep data in state to persist data when suburb changes (data will be empty while loading)
  useEffect(() => {
    if (!loading && !suburbStatisticsData) {
      setHasError(true);
    } else if (suburbStatisticsData) {
      setSuburbStatistics(suburbStatisticsData);
      setHasError(false);
    }
  }, [loading, suburbStatisticsData]);

  // Fallback in case of unexpected error or other issue
  if (hasError) {
    return (
      <div css={styles.errorWrapper}>
        <div css={styles.error}>
          Sorry, there was an issue getting sales data for{' '}
          <strong>{selectedSuburbName}</strong>.
        </div>
      </div>
    );
  }

  return (
    <div id={id} role="region" css={styles.region}>
      <AnimatePresence>
        {loading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            css={styles.loading}
          >
            <Loader />
          </motion.div>
        )}
      </AnimatePresence>
      <div css={styles.data(loading)}>
        <p aria-live="polite" css={styles.srOnly}>
          {`Now showing ${selectedSuburbName} sale details`}
          {/* String template to make screen reader read out whole sentence */}
        </p>
        <div css={styles.dataWrapper}>
          <Stats suburbStatistics={suburbStatistics} />
          <Table suburbStatistics={suburbStatistics} />
        </div>
        <p css={styles.disclaimer}>
          * Statistics are based on properties advertised on Domain. Average
          sale price and total sales values do not include properties where the
          sold price has not been disclosed.
        </p>
      </div>
    </div>
  );
};

export default Data;
