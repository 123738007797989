import { variables } from '@domain-group/fe-brary';
import { useMediaQuery } from 'beautiful-react-hooks';

// TODO: Deprecate this hook, as it causes issues with server-side rendering (mismatch)
// Ignoring in coverage because it is just an existing hook with our def of a mobile size
/* istanbul ignore next */
const useIsDesktop = (): boolean => {
  const { desktopMinWidth } = variables.global;
  return useMediaQuery(`(min-width: ${desktopMinWidth})`);
};

export default useIsDesktop;
