import React from 'react';
import {
  ItemType,
  Select as SelectInput,
} from '@domain-group/fe-blueprint-ui/select';
import * as style from './select-option.style';

const SelectOption = ({
  instanceId,
  options = [],
  handleChange,
  current = null,
  label,
  labelHidden = false,
  placeholder,
}: {
  instanceId: string;
  current?: {
    value: string;
    label: string;
  } | null;
  label: string;
  labelHidden?: boolean;
  placeholder: string;
  handleChange: (option: ItemType<string>) => void;
  options: { value: string; label: string }[];
}): JSX.Element => (
  <div css={style.inputLabelOverride}>
    <SelectInput
      instanceId={instanceId}
      label={label}
      stretched
      nowrap
      alignBody="right"
      labelHidden={labelHidden}
      appearance="borderless"
      onChange={(selected) => {
        if (selected) {
          handleChange(selected);
        }
      }}
      value={current?.value}
      aria-label={label}
      placeholder={placeholder}
      options={options}
    />
  </div>
);

export default SelectOption;
