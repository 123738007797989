export {
  AgencyHeadWithQuery,
  AgencyHeadWithFragment,
} from './agency-head-with-gql';
export {
  ContactHeadWithFragment,
  ContactHeadWithQuery,
} from './contact-head-with-gql';

// export * from './search-head-with-gql';
// export * from './static-head-with-gql'; // error page and home page
