/* eslint-disable import/prefer-default-export */
import React, { useEffect } from 'react';
import { gql } from '@apollo/client';

import { Loader, LOADER_SIZE } from '@domain-group/fe-blueprint-ui/loader';
import { handleError } from '@domain-group/fe-helper';

import { ListingCardWithFragment } from './current-listing-card';
import { ListingFilterStatus } from '../../../../generated/graphql';

import NoListingsMessage from './no-listing-message/no-listing-message';
import type { Choice } from '../../@types';

import * as styles from './listings-results.style';
import { notEmpty } from '../../../../utils';
import { useAgencyListingsResults } from '../../hooks/use-agency-listings-results';
import AD_COMPONENTS from '../../../../constants/ad-components';

export const AGENCY_CURRENT_LISTING_QUERY = gql`
  query agencyCurrentListings(
    $agencyId: Int!
    $page: Int!
    $suburbId: String
    $propertyType: AgencyApiPropertyType
    $sortBy: AgentsearchListingsSortBy
    $bedrooms: AgentsearchListingBedrooms
  ) {
    agency(agencyId: $agencyId) {
      id
      agentsearchListingsByAgencyId(
        page: $page
        pageSize: 6
        listingStatuses: [SOLD, SALE, LEASE, LEASED]
        propertyType: $propertyType
        sortBy: $sortBy
        suburbId: $suburbId
        bedrooms: $bedrooms
      ) {
        soldListings {
          ...AgentListingsTab
        }
        saleListings {
          ...AgentListingsTab
        }
        leaseListings {
          ...AgentListingsTab
        }
        leasedListings {
          ...AgentListingsTab
        }
      }
    }
  }

  fragment AgentListingsTab on AgentsearchListingResults {
    total
    totalPages
    results {
      saleType
      listing {
        ...CurrentListingCard
      }
    }
  }

  ${ListingCardWithFragment.fragment}
`;

export type AgencyListingCardsProps = {
  agencyId: number;
  bedrooms?: string;
  listingStatus?: Choice<ListingFilterStatus>;
  page: number;
  propertyType?: string;
  setTotalPages: (newPage: number) => void;
  sortBy?: string;
  suburbId?: string;
  totalPages: number;
  onListingCardClick?: () => void;
  mobilePadding?: string | number;
};

export const AgencyListingCards = ({
  agencyId,
  page,
  bedrooms,
  listingStatus,
  propertyType,
  sortBy,
  totalPages,
  suburbId,
  setTotalPages,
  onListingCardClick,
  mobilePadding,
}: AgencyListingCardsProps): JSX.Element | null => {
  const { listings, loading, error } = useAgencyListingsResults({
    agencyId,
    bedrooms,
    page,
    propertyType,
    sortBy,
    suburbId,
    listingStatus,
  });

  const newTotalPages = listings?.totalPages;

  useEffect(() => {
    if (Number.isInteger(newTotalPages) && newTotalPages !== totalPages) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      setTotalPages(newTotalPages!);
    }
  }, [newTotalPages, setTotalPages, totalPages]);

  if (loading) {
    return (
      <div css={styles.loadingContainer}>
        <Loader size={LOADER_SIZE.LARGE} />
      </div>
    );
  }

  /* istanbul ignore next */
  if (!global.window && process.env.NODE_ENV === 'development' && error) {
    return (
      <div css={styles.errorMessage} data-testid="listings-tabs__error-message">
        <>
          <span style={{ fontWeight: 'bold' }}>ERROR:</span> {error.message}
        </>
      </div>
    );
  }
  if (error) {
    handleError(error);
  }

  if (!listings) {
    return <NoListingsMessage />;
  }

  const listingIds = listings.results
    ?.map((advert) => advert?.listing?.listingId)
    .filter(notEmpty);

  // No listings
  if (!listingIds?.length) {
    return <NoListingsMessage />;
  }

  return (
    <div
      aria-live="polite"
      css={styles.listingsCardsContainer(mobilePadding)}
      data-testid="listings-tabs__listings-list"
      id="listing-results"
      role="region"
    >
      {listingIds.map(
        (id, index) =>
          id && (
            <ListingCardWithFragment
              key={id}
              adId={id}
              adComponent={AD_COMPONENTS.CurrentAgencyListings}
              index={index}
              onListingCardClick={onListingCardClick}
            />
          ),
      )}
    </div>
  );
};
