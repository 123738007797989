import React from 'react';
import { srOnly } from '@domain-group/fe-brary';
import { EmailIcon } from '@domain-group/fe-co-icon';
import { Button, LinkButton } from '@domain-group/fe-blueprint-ui/button';

import { SerializedStyles } from '@emotion/react';

export type Props = {
  name: string;
  emailFormComponentId?: string;
  onClick?: () => void;
  stretched?: boolean;
  appearance?: React.ComponentProps<typeof Button>['appearance'];
  size?: 'regular' | 'small';
  css?: SerializedStyles;
};

const EmailButton = ({
  name,
  emailFormComponentId,
  stretched = false,
  appearance = 'outline',
  size = 'regular',
  onClick = () => undefined,
  css,
}: Props): JSX.Element => {
  const handleClick = () => {
    onClick();
  };

  const Component = emailFormComponentId ? LinkButton : Button;

  return (
    <Component
      data-testid="contact__email-button"
      href={emailFormComponentId ? `#${emailFormComponentId}` : '#'}
      onClick={handleClick}
      stretched={stretched}
      appearance={appearance}
      size={size}
      css={css}
      icon={
        <EmailIcon
          name="email"
          size="small"
          data-testid="trade-profile-cta__icon"
        />
      }
    >
      <span>Email</span>
      <span css={srOnly}> {name}</span>
    </Component>
  );
};

export default EmailButton;
