import React, { useState, useEffect } from 'react';
import getConfig from 'next/config';
import { useRouter } from 'next/compat/router';
import { gql, useQuery } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import { MediaQueryProvider } from 'react-media-query-hoc';
import { Breadcrumbs } from '@domain-group/fe-co-header';
import Paginator from '@domain-group/fe-co-paginator';
import { srOnly } from '@domain-group/fe-brary';
import {
  SALES_AGENT,
  AGENCY_VIEW,
  AGENT_VIEW,
  DEFAULT_SORT_LABEL,
  DEFAULT_SORT_VALUE,
  SORT_OPTIONS_AGENCIES,
  SORT_OPTIONS_AGENTS,
} from './components/search-filter-bar/enums';
import {
  AppraisalRequestCard,
  SearchResultsHead,
  SellingGuideCard,
  TwoColumnLayout,
} from '..';
import SearchResultsFooterWithFragment from '../footer/search-results-footer-with-fragment';
import { ContactSearchProfileCardWithFragment } from '../contact-profile-card';
import { AgencySearchProfileCardWithFragment } from '../agency-profile-card';
import { mapSearchResultsBreadcrumbs } from '../header/utils';
import { Option } from './components/search-filter-bar/filters-bar/pill-forms/filters-form';
import {
  SearchFilterBar,
  SearchSwitchViewButtons,
  SortBy,
  Loader,
  Results,
} from './components';
import { buildUrl, buildPaginatorUrl, getSearchFromQuery } from './utils';
import { notEmpty } from '../../utils';
import { UserDetailsRedacted } from '../../@types';
import { AgencySearchQuery } from '../../generated/graphql';
import {
  trackViewSearchResultsPage,
  trackFilterResults,
  trackSelectSortOrder,
  trackViewTooltip,
} from '../../tracking/search-results';

import * as styles from './search-results.style';

const { publicRuntimeConfig } = getConfig();

export const SEARCH_QUERY = gql`
  query AgencySearch(
    $suburbSlugs: [String!]!
    $agencySortBy: AgencySearchSortBy
    $contactSortBy: ContactSearchSortBy
    $page: Int
    $pageSize: Int
    $agentFilter: AgentSearchContactTypes
    $keywordName: String
    $includeAgents: Boolean
    $isAgencyView: Boolean!
  ) {
    contactSearchBySuburbs(
      suburbSlugs: $suburbSlugs
      sortBy: $contactSortBy
      page: $page
      pageSize: $pageSize
      agentType: $agentFilter
      agentNameKeyword: $keywordName
    ) {
      total
      totalPages
      pageNumber
      results @skip(if: $isAgencyView) {
        id
        ...ContactSearchProfileCard
      }
    }
    agencySearchBySuburbs(
      suburbSlugs: $suburbSlugs
      sortBy: $agencySortBy
      page: $page
      pageSize: $pageSize
      agencyNameKeyword: $keywordName
      includeAgents: $includeAgents
    ) {
      total
      totalPages
      pageNumber
      results @include(if: $isAgencyView) {
        id
        ...AgencySearchProfileCard
      }
    }
    locationProfiles(urlSlugs: $suburbSlugs) {
      id
      suburbName
      state
      postcode
      urlSlug
      ...SearchResultsFooter @include(if: $isAgencyView)
    }
  }
  ${ContactSearchProfileCardWithFragment.fragment}
  ${AgencySearchProfileCardWithFragment.fragment}
  ${SearchResultsFooterWithFragment.fragment}
`;

interface SearchResultsProps {
  mixpanelHasInitialized?: boolean;
  isEmbeddedApp?: boolean;
  skipLinkContentId: string;
  user?: UserDetailsRedacted;
  // Initial (SSR) search
  sortBy?: string;
  keywordName?: string;
  agentFilter?: string;
  page: string;
  isAgency: boolean;
  suburbs: string;
}

const SearchResults = ({
  mixpanelHasInitialized = false,
  isEmbeddedApp = false,
  skipLinkContentId,
  user,
  // Initial (SSR) search
  page: initialPage,
  keywordName: initialKeyword,
  agentFilter: initialAgentFilter,
  sortBy: initialSortBy,
  isAgency: initialIsAgency,
  suburbs: initialSuburbs,
}: SearchResultsProps): JSX.Element => {
  const router = useRouter();

  // Record page view
  useEffect(() => {
    if (mixpanelHasInitialized) {
      trackViewSearchResultsPage();
    }
  }, [router?.asPath, mixpanelHasInitialized]); // asPath = The path (including the query) shown in the browser without the configured basePath or locale.

  // Route changing loading (eg. between /real-estate-agents/ and /real-estate-agencies/)
  const [routeLoading, setLoading] = useState(false);
  useEffect(() => {
    const handleStart = () => {
      setLoading(true);
    };

    const handleComplete = () => {
      setLoading(false);
    };

    router?.events.on('routeChangeStart', handleStart);
    router?.events.on('routeChangeComplete', handleComplete);

    return () => {
      router?.events.off('routeChangeStart', handleStart);
      router?.events.off('routeChangeComplete', handleComplete);
    };
  }, [router]);

  const state = getSearchFromQuery(
    {
      isAgency: initialIsAgency,
      suburbs: initialSuburbs,
      keywordName: initialKeyword,
      page: initialPage ?? 1,
      agentFilter: initialAgentFilter ?? SALES_AGENT,
      sortBy: initialSortBy ?? DEFAULT_SORT_VALUE,
    },
    router?.query,
    router?.pathname,
  );

  // Enforce canonical URLs by checking if the current URL matches a canonical URL
  // useEffect(() => {
  //   if (state && router.asPath) {
  //     const { to: toPath, as: asPath } = buildUrl(state);
  //     if (router.asPath !== asPath) {
  //       router.replace(toPath, asPath, { shallow: true });
  //     }
  //   }
  // }, [state, router]);

  // Use state to persist data while client-side loading
  const [localData, setLocalData] = useState<AgencySearchQuery>();
  // Page query
  const {
    data: queryData,
    loading,
    error,
  } = useQuery<AgencySearchQuery>(SEARCH_QUERY, {
    variables: {
      suburbSlugs: state?.suburbs,
      page: state?.page,
      pageSize: 15,
      isAgencyView: state?.isAgency,
      keywordName: state?.keywordName,
      // Contact parameters
      contactSortBy: state?.sortBy, // TODO: url is set as startCase, avoid breaking change
      agentFilter: state?.agentFilter === SALES_AGENT ? 'sale' : 'rent',
      // Agency parameters
      agencySortBy: state?.sortBy, // TODO: url is set as startCase, avoid breaking change
      includeAgents: true,
    },
    errorPolicy: 'all', // https://www.apollographql.com/docs/react/v2/data/error-handling/#error-policies
  });

  // Keep local copy of data for retaining UI while loading new pages (client-side routing)
  useEffect(() => {
    if (!loading && queryData) {
      setLocalData(queryData);
    }
  }, [loading, queryData]);

  // Preference query data, but fallback to local data (eg. when moving between pages, query data will be empty)
  const data = queryData || localData;

  // Filter out malformed or missing suburbs (Eg. if a bad suburb slug was in the URL)
  const actualLocationProfiles =
    data?.locationProfiles?.filter((profile) => !!profile?.urlSlug) ?? [];

  if (
    typeof window !== 'undefined' &&
    !routeLoading &&
    !loading &&
    (!state || !actualLocationProfiles.length)
  ) {
    // If no suburbs, redirect to landing page
    // Needs to happen client-side (can't redirect here during getServerSideProps)
    router?.push?.('/real-estate-agents');
  }

  if (error) {
    throw new Error(`Something went wrong, ${error}`);
  }

  if (!data || !state || !actualLocationProfiles.length) {
    return <Loader />;
  }

  const { isAgency, suburbs, page, keywordName, agentFilter, sortBy } = state;

  const searchView = isAgency ? AGENCY_VIEW : AGENT_VIEW;

  const breadcrumbs = mapSearchResultsBreadcrumbs({
    isAgent: !isAgency,
    suburbs: data?.locationProfiles,
  });

  const changePage = (filters: {
    suburbs?: string[];
    page?: number;
    keywordName?: string;
    agentFilter?: string;
    sortBy?: string; // TODO: Use enum
    isAgency?: boolean;
  }): void => {
    // Avoid doing client-side routing if the suburbs are changing
    // This is a somewhat hacky fix - we should look at using virtual page view events instead
    const shouldBeShallow = typeof suburbs === 'undefined';

    const newState = {
      ...state,
      ...filters,
    };

    const { to: toPath, as: asPath } = buildUrl(newState);

    router?.push(toPath, asPath, {
      // https://nextjs.org/docs/routing/shallow-routing
      shallow: shouldBeShallow,
    });
  };

  const handlePageChange = (event: React.SyntheticEvent, newPage: number) => {
    event.preventDefault();

    window?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    changePage({
      page: newPage,
    });
  };

  // Change filters/state
  const selectSuburbs = (newSuburbs: Option[] | null) => {
    changePage({
      suburbs: newSuburbs?.map?.(({ value }) => value) ?? [],
    });
  };

  const selectFilter = (newFilter: string | null) => {
    const formattedNewFilter = newFilter ?? '';
    trackFilterResults(formattedNewFilter);
    changePage({
      keywordName: formattedNewFilter,
    });
  };

  const setSortBy = (newSortBy: string) => {
    const SORT_OPTIONS = isAgency ? SORT_OPTIONS_AGENCIES : SORT_OPTIONS_AGENTS;
    const sortOption = SORT_OPTIONS.find((item) => item.value === newSortBy);

    trackSelectSortOrder(sortOption?.label ?? DEFAULT_SORT_LABEL);
    changePage({
      sortBy: sortOption?.value ?? DEFAULT_SORT_VALUE,
    });
  };

  const selectAgentType = (newAgentType: string | null) => {
    changePage({
      agentFilter: newAgentType ?? SALES_AGENT,
    });
  };

  const selectSearchTypeAndFilters = (
    newSearchType: string | null,
    newAgentType: string | null,
    newFilter: string | null,
  ) => {
    const formattedOldFilter = state.keywordName ?? '';
    const formattedNewFilter = newFilter ?? '';
    if (newFilter !== formattedOldFilter) {
      trackFilterResults(formattedNewFilter);
    }

    const isAgencyNew = newSearchType === AGENCY_VIEW;
    changePage({
      isAgency: isAgencyNew,
      // Reset other filters
      page: 1,
      ...(isAgencyNew ? {} : { agentFilter: newAgentType ?? SALES_AGENT }),
      keywordName: formattedNewFilter,
      sortBy: DEFAULT_SORT_VALUE,
    });
  };

  const selectSearchType = (newSearchType: string | null) => {
    changePage({
      isAgency: newSearchType === AGENCY_VIEW,
      // Reset other filters
      page: 1,
      agentFilter: SALES_AGENT,
      keywordName: '',
      sortBy: DEFAULT_SORT_VALUE,
    });
  };

  const isLoading = !!(loading || routeLoading);

  const agencyIds = isAgency
    ? data?.agencySearchBySuburbs?.results?.map((agency) => agency?.id)
    : data?.contactSearchBySuburbs?.results?.map(
        (contact) => contact?.agencyId,
      );
  const filteredAgencyIds = agencyIds?.filter(notEmpty) ?? [];
  const deduplicatedAgencyIds = [...new Set(filteredAgencyIds)];

  const urlTemplate = buildPaginatorUrl({
    isAgency,
    suburbs,
    sortBy,
    agentFilter,
    keywordName,
    page,
  });

  return (
    <MediaQueryProvider values={{ width: 300, type: 'screen' }}>
      <div id={skipLinkContentId} data-testid="trade-search-results__page">
        <SearchResultsHead
          isAgency={isAgency}
          total={
            isAgency
              ? data?.agencySearchBySuburbs?.total
              : data?.contactSearchBySuburbs?.total
          }
          totalPages={
            isAgency
              ? data?.agencySearchBySuburbs?.totalPages
              : data?.contactSearchBySuburbs?.totalPages
          }
          pageNumber={page}
          suburbOptions={actualLocationProfiles}
          suburbSlugsArray={suburbs}
          sortBy={sortBy}
          agentType={agentFilter}
          keyword={keywordName}
          breadcrumbs={breadcrumbs}
          agencyIds={deduplicatedAgencyIds}
          user={user}
        />
        <SearchFilterBar
          isAgency={isAgency}
          suburbOptions={actualLocationProfiles}
          selectSuburbs={selectSuburbs}
          selectSearchType={selectSearchType}
          currentSearchType={searchView}
          selectAgentType={selectAgentType}
          currentAgentType={agentFilter}
          selectFilter={selectFilter}
          currentFilter={keywordName}
          selectSearchTypeAndFilters={selectSearchTypeAndFilters}
        />
        {!isEmbeddedApp && (
          <div css={styles.breadcrumbWrapper}>
            <Breadcrumbs
              baseUrl={publicRuntimeConfig.BASE_URL}
              breadcrumbs={breadcrumbs}
              backButtonLabel="Back"
              gutter
            />
          </div>
        )}
        <main>
          <TwoColumnLayout marginBottom="l">
            <h1 css={styles.title} aria-live="polite">
              <span css={srOnly}>Found </span>
              {isAgency
                ? data?.agencySearchBySuburbs?.total
                : data?.contactSearchBySuburbs?.total}{' '}
              real estate {isAgency ? 'agencies' : 'agents'} active in{' '}
              {actualLocationProfiles[0]?.suburbName ||
                `${actualLocationProfiles.length ?? 0} suburbs`}
            </h1>
            <p css={styles.subTitle}>
              Listing counts are for properties advertised on Domain within
              searched location.
            </p>
            <div css={styles.sortGroup}>
              <SearchSwitchViewButtons
                isAgency={isAgency}
                onClick={selectSearchType}
                agencyCount={data?.agencySearchBySuburbs?.total}
                contactCount={data?.contactSearchBySuburbs?.total}
              />
              <SortBy
                isAgency={isAgency}
                setSortBy={setSortBy}
                selectedSortBy={sortBy}
                viewSortByTooltip={trackViewTooltip}
              />
            </div>
          </TwoColumnLayout>
          <TwoColumnLayout
            aside={
              <>
                <AppraisalRequestCard />
                <SellingGuideCard />
              </>
            }
            marginBottom="l"
          >
            <AnimatePresence>
              {isLoading && (
                <motion.div
                  key="loading"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                >
                  <Loader absolute />
                </motion.div>
              )}
            </AnimatePresence>
            <motion.div
              css={styles.resultsGroup}
              animate={{
                opacity: isLoading ? 0.5 : 1,
              }}
            >
              <Results
                isAgency={isAgency}
                agentType={agentFilter}
                agentResults={data?.contactSearchBySuburbs?.results}
                agencyResults={data?.agencySearchBySuburbs?.results}
                userId={user?.userId}
                userToken={user?.userToken}
                suburbSlugs={actualLocationProfiles
                  .map((location) => location?.urlSlug)
                  .filter(notEmpty)}
              />
            </motion.div>
            <div css={styles.pagination} data-testid="search-paginator">
              <Paginator
                totalPages={
                  isAgency
                    ? data?.agencySearchBySuburbs?.totalPages
                    : data?.contactSearchBySuburbs?.totalPages
                }
                currentPage={page}
                urlTemplate={urlTemplate}
                onPageChange={handlePageChange}
              />
            </div>
          </TwoColumnLayout>
        </main>
        {isEmbeddedApp ? null : (
          <SearchResultsFooterWithFragment
            id={
              actualLocationProfiles.length === 1
                ? actualLocationProfiles[0]?.id
                : null
            }
          />
        )}
      </div>
    </MediaQueryProvider>
  );
};

export default SearchResults;
