/* eslint-disable import/prefer-default-export */
import React from 'react';

import {
  ItemType,
  Select as SelectInput,
} from '@domain-group/fe-blueprint-ui/select';

import * as style from './select-dropdown.style';
import type { Choice } from '../../../@types';

export type SelectOptionProps = {
  instanceId: string;
  options?: Choice<string>[];
  handleChange: (newChoice?: Choice<string>) => void;
  current?: Choice<string>;
  label: string;
  labelHidden?: boolean;
};

export const SelectOption = ({
  instanceId,
  options = [],
  handleChange,
  current,
  label,
  labelHidden,
}: SelectOptionProps): JSX.Element => (
  <div css={style.inputLabelOverride} aria-controls="listingResults">
    <SelectInput
      instanceId={instanceId}
      label={label}
      stretched
      nowrap
      alignBody="right"
      labelHidden={labelHidden}
      appearance="borderless"
      onChange={(selected) => {
        const relatedChoice = options.find(
          (option) => option.value === selected?.value,
        );
        handleChange(relatedChoice);
      }}
      value={current?.value}
      aria-label={label}
      placeholder="Newest"
      options={options as ItemType<string>[]}
    />
  </div>
);
