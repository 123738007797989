import React from 'react';
import { Loader } from '@domain-group/fe-blueprint-ui/loader';
import * as styles from './loading.style';

const Loading = (): JSX.Element => (
  <div css={styles.loadingPage}>
    <div css={styles.loading}>
      <Loader />
    </div>
  </div>
);

export default Loading;
