/* eslint-disable import/prefer-default-export */
import React from 'react';

import { Button } from '@domain-group/fe-blueprint-ui/button';

import * as style from './end-form-button-group.style';

type Props = {
  pillLabel: string;
  close: () => void;
  handleSubmit: (event: React.FormEvent<EventTarget>) => void;
};

export const EndFormButtonGroup = ({
  pillLabel,
  close,
  handleSubmit,
}: Props): JSX.Element => (
  <div css={style.pillModalwrapper}>
    <div css={style.buttonGroupWrapper}>
      <Button
        onClick={close}
        appearance="muted"
        css={style.cancelButtonStyleOverride}
      >
        Cancel <span css={style.srOnly}> filtering by {pillLabel}</span>
      </Button>
      <Button type="submit" onClick={handleSubmit}>
        See Results
      </Button>
    </div>
  </div>
);
