import React from 'react';
import PinIcon from '@domain-group/fe-co-icon/lib/js/pin';
import RetailIcon from '@domain-group/fe-co-icon/lib/js/retail';
import { Avatar } from '@domain-group/fe-blueprint-ui/avatar';
import { Option } from '../types';

export interface OptionIconProps {
  option: Option;
}

const OptionIcon = ({
  option: { category, avatar },
}: OptionIconProps): JSX.Element | null => {
  switch (category) {
    case 'Suburb':
      return <PinIcon />;
    case 'Agent':
      return <Avatar size="small" colorScheme="light" imageUrl={avatar} />;
    case 'Agency':
      return <RetailIcon />;
    default:
      return null;
  }
};

export default OptionIcon;
