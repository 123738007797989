import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/compat/router';
import { useApolloClient } from '@apollo/client';
import { MediaContext } from 'react-media-query-hoc';
import { Message } from '@domain-group/fe-co-messages';
import SearchIcon from '@domain-group/fe-co-icon/lib/js/search';
import Typeahead from '@domain-group/fe-co-typeahead';

import optionType from './components/option-type';
import { Option } from './types';
import { suggestLocations, uniqBy } from './utils';

import * as styles from './search-autocomplete.style';

export interface SearchAutocompleteProps {
  initialSelectedOption?: Option[];
  performSearchQuery?: (suburbs: Option[]) => void;
  isLocationSearchByAgent?: boolean;
  icons?: boolean;
  recentSearches?: boolean;
  appearance?: 'large' | 'medium' | 'small';
  minimumInput?: number;
}

const SearchAutocomplete = ({
  initialSelectedOption = [],
  performSearchQuery,
  isLocationSearchByAgent = false,
  icons = true,
  recentSearches: enableRecentSearches = false,
  appearance = 'medium',
  minimumInput = 2,
}: SearchAutocompleteProps): JSX.Element => {
  const apolloClient = useApolloClient();
  const router = useRouter();
  const media = useContext<{ mobile?: boolean }>(MediaContext);
  const [input, setInput] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [recentSearches, setRecentSearches] = useState<Option[]>([]);
  const [selectedValues, setSelectedValues] = useState<Option[]>(
    initialSelectedOption,
  );
  const [error, setError] = useState<string | null>(null);
  const [noResults, setNoResults] = useState<string | null>(null);

  useEffect(() => {
    if (enableRecentSearches) {
      // Get recent searches from session storage
      const storedRecentSearches =
        window.sessionStorage.getItem('recentSearches');

      if (storedRecentSearches) {
        setRecentSearches(JSON.parse(storedRecentSearches) || []);
      }
    }
  }, [enableRecentSearches]);

  const redirectUser = (value: string) => {
    router?.push(value, undefined, { shallow: true });
  };

  const handleAutocompleteChange = (values: Option[]) => {
    const selectedItem =
      values && values.length > 0 ? values[values.length - 1] : null;

    if (selectedItem && selectedItem.redirectToURL && selectedItem.url) {
      redirectUser(selectedItem.url);
    } else {
      setSelectedValues(values);
    }
  };

  const handleRemove = ({ item }: { item: Option }) => {
    const newSelectedValues = selectedValues.filter(
      (selectedValue) => selectedValue.value !== item.value,
    );
    handleAutocompleteChange(newSelectedValues);
  };

  const handleSelect = (
    { item }: { item: Option },
    { closeMenu }: { closeMenu: () => void },
  ) => {
    const newSelectedValues = [...selectedValues, item];

    handleAutocompleteChange(newSelectedValues);

    setInput('');
    closeMenu();
  };

  const performSearch = (
    event?:
      | React.KeyboardEvent<HTMLInputElement>
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event?.preventDefault();

    if (selectedValues.length === 0) {
      setError('Oops… we need a location or name.');
      setNoResults(null);
      return;
    }

    if (recentSearches) {
      // Deduplicate recent searches
      const newRecentSearches = uniqBy(
        recentSearches.concat(selectedValues),
        (item: { value: string }) => item.value,
      );

      window.sessionStorage.setItem(
        'recentSearches',
        JSON.stringify(newRecentSearches),
      );
    }

    if (performSearchQuery) {
      performSearchQuery(selectedValues);
    } else {
      const searchPath = isLocationSearchByAgent
        ? 'real-estate-agents'
        : 'real-estate-agencies';

      if (selectedValues.length === 1) {
        redirectUser(`/${searchPath}/${selectedValues[0].value}/`);
      } else {
        const queries = selectedValues.map((suburb) => suburb.value).join(',');
        redirectUser(`/${searchPath}/?suburbs=${encodeURIComponent(queries)}`);
      }
    }
  };

  const hasNoResults = (): JSX.Element => (
    <div css={styles.noResultsMessage}>
      Sorry, we didn’t find any matches for &quot;{input}&quot;. {noResults}
    </div>
  );

  const handleInputChange = (newInput: string) => {
    setInput(newInput);

    if (!open) {
      setMenuOpen(true);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // keyCode is deprecated, but key is unavailable in some older browsers
    const isEnterKey = event.key === 'Enter' || event.keyCode === 13;
    const isEscapeKey = event.key === 'Escape';

    if (isEscapeKey) {
      setMenuOpen(false);
    } else if (input.length === 0 && isEnterKey) {
      performSearch(event);
    }
  };

  const handleBlur = () => {
    setMenuOpen(false);
  };

  const handleClick = () => {
    setMenuOpen(true);
  };

  const handleFocus = () => {
    // The input field is automatically refocused by fe-co-typeahead when closing the fullscreen mode, so it will be
    // uncloseable if we immediately reopen.
    if (!media?.mobile) {
      setMenuOpen(true);
    }
  };

  const recentSearchesToDisplay = recentSearches.filter(
    (item) => !selectedValues.map(({ value }) => value).includes(item.value),
  );

  return (
    <form
      css={styles.autocomplete(appearance)}
      data-testid="find-an-agent-autocomplete"
      onSubmit={performSearch}
    >
      <div
        css={styles.typeaheadContainer}
        data-testid="find-an-agent-autocomplete-search__search-bar-inner"
      >
        <div css={styles.typeahead(appearance)}>
          <Typeahead
            instanceId="searchautocomplete"
            enableFullScreen={media.mobile}
            // Full screen portal has a small bug where
            // the input field doesn't focus on opening
            // (ie. keyboard doesn't pop up)
            enableFullScreenPortal
            shouldSubmitImmediatelyBlockUI
            input={input}
            open={menuOpen}
            selectedItems={selectedValues}
            modes={{
              match: {
                label: 'match',
                placeholder: 'Search suburb, agent or agency',
                fetchOptions: ({
                  input: autoCompleteInput,
                }: {
                  input: string;
                }) =>
                  suggestLocations({
                    input: autoCompleteInput,
                    apolloClient,
                    setError,
                    setNoResults,
                  }),
                onRemove: handleRemove,
                onSelect: handleSelect,
              },
            }}
            initialMode="match"
            media={false}
            appearance={appearance}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onBackClick={handleBlur}
            onClose={handleBlur}
            recentSearches={recentSearchesToDisplay}
            optionType={optionType(icons)}
            showKeyword={false}
            showNoOptionsMessage={false}
            customMessage={
              !!noResults && input.length > minimumInput
                ? hasNoResults
                : undefined
            }
            minimumInput={minimumInput}
            // eslint-disable-next-line react/no-unstable-nested-components
            rhs={({ isFullScreen }: any) =>
              isFullScreen ? (
                <div css={styles.searchButtonFullScreen}>
                  <button
                    type="button"
                    onClick={performSearch}
                    css={styles.searchButton(appearance, isFullScreen)}
                  >
                    <span css={styles.srOnly}>Search</span>
                    <SearchIcon size="custom" />
                  </button>
                </div>
              ) : null
            }
          />
        </div>
        <button type="submit" css={styles.searchButton(appearance)}>
          <span css={styles.srOnly}>Search</span>
          <SearchIcon size="custom" />
        </button>
      </div>

      {error && (
        <div
          css={styles.errorMessage}
          data-testid="find-an-agent-autocomplete__error-message"
        >
          <Message type="error" headline={error} />
        </div>
      )}
    </form>
  );
};

export default SearchAutocomplete;
