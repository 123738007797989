import React from 'react';
import { srOnly } from '@domain-group/fe-brary';
import { Button, ButtonGroup } from '@domain-group/fe-blueprint-ui/button';

import Badge from '../badge/badge';
import { AGENCY_VIEW, AGENT_VIEW } from '../search-filter-bar/enums';

import * as styles from './search-switch-view-buttons.style';

const SearchSwitchViewButtons = ({
  isAgency,
  onClick,
  agencyCount,
  contactCount,
}: {
  isAgency: boolean;
  onClick: (view: string) => void;
  agencyCount?: number | null;
  contactCount?: number | null;
}): JSX.Element => (
  <div css={styles.linksContainer}>
    <ButtonGroup>
      <Button
        appearance="outlineAccent"
        type="button"
        css={styles.buttonSwitch(!isAgency)}
        disabled={!isAgency}
        onClick={() => onClick(AGENT_VIEW)}
      >
        <span css={srOnly}>View all {contactCount} agent search results</span>
        <span
          data-testid="list-view-button-label"
          css={styles.buttonLabel}
          aria-hidden
        >
          Agents
        </span>
        <span css={styles.buttonBadge}>
          {typeof contactCount === 'number' && (
            <Badge badgeCount={contactCount} isActive={!isAgency} />
          )}
        </span>
      </Button>
      <Button
        appearance="outlineAccent"
        type="button"
        css={styles.buttonSwitch(isAgency)}
        disabled={isAgency}
        onClick={() => onClick(AGENCY_VIEW)}
      >
        <span css={srOnly}>View all {agencyCount} agency search results</span>
        <span
          data-testid="map-view-button-label"
          css={styles.buttonLabel}
          aria-hidden
        >
          Agencies
        </span>
        <span css={styles.buttonBadge}>
          {typeof agencyCount === 'number' && (
            <Badge badgeCount={agencyCount} isActive={isAgency} />
          )}
        </span>
      </Button>
    </ButtonGroup>
  </div>
);

export default SearchSwitchViewButtons;
