import React, { useState, useRef } from 'react';
import { srOnly } from '@domain-group/fe-brary';
import { PhoneIconSmall } from '@domain-group/fe-co-icon';
import { Button, LinkButton } from '@domain-group/fe-blueprint-ui/button';
import { SerializedStyles } from '@emotion/react';
import {
  addAusCodeToPhoneNumber,
  formatTelephoneNumber,
} from '../../../../utils';

export type Props = {
  phoneNumber: string | number;
  name: string;
  stretched?: boolean;
  size?: 'small' | 'regular';
  appearance?: 'outline' | 'highlight' | 'ghost';
  css?: SerializedStyles;
  onCall?: () => void;
  onCallReveal?: () => void;
};

const CallButton = ({
  phoneNumber,
  name,
  stretched = false,
  size = 'regular',
  appearance = 'outline',
  css,
  onCall = () => undefined,
  onCallReveal = () => undefined,
}: Props): JSX.Element => {
  const [isRevealed, setIsRevealed] = useState(false);
  const buttonEl = useRef<HTMLAnchorElement>(null);

  const keyDown = (event: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (event.key === 'Enter') {
      setIsRevealed(true);
      onCallReveal();
      setTimeout(() => {
        // Timeout is required to allow a re-render before trying to set focus
        buttonEl?.current?.focus();
      }, 0);
    }
  };

  // eg. +61446221123
  const internationalFormattedPhoneNumber = addAusCodeToPhoneNumber(
    `${phoneNumber}`,
  );

  // eg. 0446 221 123
  const formattedPhoneNumber = formatTelephoneNumber(
    internationalFormattedPhoneNumber,
  );

  if (isRevealed) {
    return (
      <LinkButton
        href={`tel:${internationalFormattedPhoneNumber}`}
        ref={buttonEl}
        onClick={() => onCall()}
        stretched={stretched}
        appearance={appearance}
        size={size}
        css={css}
        data-testid="cta-call-number"
      >
        <span css={srOnly}>Call {name} on </span>
        {formattedPhoneNumber}
      </LinkButton>
    );
  }

  return (
    <Button
      type="button"
      onClick={() => {
        setIsRevealed(true);
        onCallReveal();
      }}
      onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) =>
        keyDown(event)
      }
      stretched={stretched}
      appearance={appearance}
      size={size}
      css={css}
      data-testid="cta-call-button"
      icon={
        <PhoneIconSmall
          name="Phone"
          size="small"
          data-testid="trade-profile-cta__icon"
        />
      }
    >
      <span css={srOnly}>Reveal phone number to </span>
      <span>Call</span>
      <span css={srOnly}> {name}</span>
    </Button>
  );
};

export default CallButton;
