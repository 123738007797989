import React from 'react';
import Paginator from '@domain-group/fe-co-paginator';
import { handleError } from '@domain-group/fe-helper';

import {
  FiltersBar,
  ListingStatusTabs,
  ContactListingCards,
} from './components';
import {
  useFilterState,
  useContactListingFilters,
  FilterStateTracking,
} from './hooks';
import { useQueryParams } from '../../hooks';

import fadeIn from '../../styles/fade-in.style';
import * as styles from './current-listings.style';
import { ListingFilterStatusId } from './@types';
import {
  LISTINGS_TAB_CATEGORY_PARAM,
  LISTINGS_TAB_PAGE_PARAM,
} from '../../constants/query-params';

export type Props = {
  contactId: number;
  tracking?: FilterStateTracking;
  mobilePadding?: number | string;
};

const ContactCurrentListingCardsAndFilters = ({
  contactId,
  tracking = {},
  mobilePadding,
}: Props): JSX.Element | null => {
  // Restore Tab and Page state from query params
  const queryParams = useQueryParams();
  const tabPage = queryParams[LISTINGS_TAB_PAGE_PARAM];
  const currentTab = queryParams[LISTINGS_TAB_CATEGORY_PARAM];
  const initialListingStatusId = currentTab
    ? (currentTab as ListingFilterStatusId)
    : undefined;

  const initialPage = tabPage ? parseInt(tabPage as string, 10) : undefined;
  // State
  const {
    currentSuburb,
    currentBedrooms,
    currentPropertyType,
    currentSortBy,
    page,
    totalPages,
    setTotalPages,
    selectBedrooms,
    changeToNewPage,
    selectPropertyType,
    selectSortBy,
    selectSuburb,
    listingStatus,
    selectListingStatus,
    clearAll,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  } = useFilterState({
    initialPage,
    initialTabId: initialListingStatusId,
    tracking,
  });

  // Handlers
  const handlePageChange = (
    event: React.FormEvent<EventTarget>,
    newPage: number,
  ) => {
    event.preventDefault();
    changeToNewPage(newPage);
  };

  // Query
  const {
    error,
    bedroomsOptions,
    currentListingStatus: defaultListingStatus,
    listingStatusOptions,
    propertyTypesOptions,
    sortByOptions,
    suburbSlugsOptions,
  } = useContactListingFilters({
    contactId,
    listingStatus,
    initialListingStatusId,
  });

  /* istanbul ignore next */
  if (!global.window && process.env.NODE_ENV === 'development' && error) {
    return (
      <div css={styles.errorMessage} data-testid="listings-tabs__error-message">
        <>
          <span style={{ fontWeight: 'bold' }}>ERROR:</span> {error.message}
        </>
      </div>
    );
  }

  if (error) {
    handleError(error);
  }

  if (defaultListingStatus && defaultListingStatus !== listingStatus) {
    selectListingStatus(defaultListingStatus);
  }

  const moreThanOnePage = !!(totalPages > 1);
  const hasListingStatus = !!defaultListingStatus;

  return (
    <div data-testid="listings-tabs__main">
      {hasListingStatus ? (
        <ListingStatusTabs
          id="current-listings-status-tabs"
          currentListingStatus={listingStatus || defaultListingStatus}
          listingStatuses={listingStatusOptions}
          selectListingStatus={selectListingStatus}
        />
      ) : null}
      <FiltersBar
        bedroomsOptions={bedroomsOptions}
        propertyTypeOptions={propertyTypesOptions}
        sortByOptions={sortByOptions}
        suburbOptions={suburbSlugsOptions}
        selectBedrooms={selectBedrooms}
        selectPropertyType={selectPropertyType}
        selectSortBy={selectSortBy}
        selectSuburb={selectSuburb}
        clearAll={clearAll}
        currentBedrooms={currentBedrooms}
        currentPropertyType={currentPropertyType}
        currentSortBy={currentSortBy}
        currentSuburb={currentSuburb}
        mobilePadding={mobilePadding}
      />
      <ContactListingCards
        bedrooms={currentBedrooms?.value}
        contactId={contactId}
        listingStatus={listingStatus || defaultListingStatus}
        propertyType={currentPropertyType?.value}
        sortBy={currentSortBy?.value}
        suburbId={currentSuburb?.value}
        setTotalPages={setTotalPages}
        totalPages={totalPages}
        page={page}
        onListingCardClick={() =>
          listingStatus?.label &&
          tracking?.onListingCardClick?.(listingStatus?.label)
        }
        mobilePadding={mobilePadding}
      />
      {moreThanOnePage ? (
        <div css={fadeIn(0)}>
          <Paginator
            small
            totalPages={totalPages}
            currentPage={page}
            urlTemplate={`?${LISTINGS_TAB_CATEGORY_PARAM}=${
              (listingStatus || defaultListingStatus)?.id
            }&${LISTINGS_TAB_PAGE_PARAM}={{ page }}`} // This isn't used to change page
            onPageChange={handlePageChange}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ContactCurrentListingCardsAndFilters;
