import React from 'react';
import { Radio } from '@domain-group/fe-blueprint-ui/form';
import { IActiveSuburb } from '../../@types';
import * as styles from './controls.style';

export interface RadioButtonProps {
  id: string;
  suburb: IActiveSuburb;
  selectedSuburb: string;
  onSelectChange: (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>,
  ) => void;
}

const RadioButton = ({
  id,
  suburb,
  selectedSuburb,
  onSelectChange,
}: RadioButtonProps): JSX.Element => {
  const isSelected = selectedSuburb === suburb.suburbSlug;

  return (
    <div css={styles.fieldWrapper}>
      <Radio
        aria-controls={id}
        name={suburb.suburbName}
        id={suburb.suburbSlug}
        value={suburb.suburbSlug}
        onChange={onSelectChange}
        size="large"
        labelContent={
          <span css={styles.radioLabel(isSelected)}>
            {suburb.suburbName}
            <span>
              <span css={styles.srOnly}> with </span>
              <span css={styles.pill(isSelected)}>{suburb.listingCount}</span>
              <span css={styles.srOnly}> properties sold</span>
            </span>
          </span>
        }
        checked={isSelected}
      />
    </div>
  );
};

export default RadioButton;
