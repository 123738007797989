import React, { useState } from 'react';
import { gql, useFragment } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { AnimatePresence, motion } from 'framer-motion';
import { Button } from '@domain-group/fe-blueprint-ui/button';
import { toGlobalId, arrayFilterNotEmpty } from '../../utils';
import uniqueByKey from '../../utils/array-unique-by-key';
import {
  AgencyTeamFragment,
  ContactProfileCardFragment,
} from '../../generated/graphql';
import { ContactProfileCardWithFragment } from '../simple-contact-profile-card';

import * as styles from './agency-team.style';

export type Props = {
  agencyId: number;
  mobilePadding?: string | number;

  hideAppraisalCta?: boolean;

  // Events
  onViewMore?: () => void;
  // Profile Card events
  onAgentViewProfile?: () => void;
  onAgentCall?: () => void;
  onAgentCallReveal?: () => void;
  onAgentGetAppraisal?: () => void;
  onAgentOpenEnquiryForm?: () => void;
  // Profile Card Enquiry Form events
  onAgentEnquirySubmit?: () => void;
  onAgentEnquirySubmitSuccess?: () => void;
  onAgentEnquirySubmitError?: () => void;
  onAgentEnquiryFinish?: (() => void) | undefined;
};

const TYPE_NAME = 'Agency';
const FRAGMENT_NAME = 'AgencyTeam';
const FRAGMENT = gql`
  fragment AgencyTeam on Agency {
    id
    agencyId
    contacts {
      contactId
      agentIdV2
      ...ContactProfileCard
    }
  }
  ${ContactProfileCardWithFragment.fragment}
`;

const AgencyTeamWithFragment = ({
  agencyId,
  mobilePadding,
  hideAppraisalCta = false,
  onViewMore = () => undefined,
  onAgentViewProfile = () => undefined,
  onAgentCall = () => undefined,
  onAgentCallReveal = () => undefined,
  onAgentGetAppraisal = () => undefined,
  onAgentOpenEnquiryForm = () => undefined,
  onAgentEnquirySubmit = () => undefined,
  onAgentEnquirySubmitSuccess = () => undefined,
  onAgentEnquirySubmitError = () => undefined,
  onAgentEnquiryFinish = () => undefined,
}: Props): JSX.Element | null => {
  const [isTeamExpanded, setIsTeamExpanded] = useState(false);
  const id = toGlobalId(TYPE_NAME, agencyId);

  const { complete, data: agency } = useFragment<AgencyTeamFragment, unknown>({
    from: {
      __typename: TYPE_NAME,
      id,
    },
    fragment: AgencyTeamWithFragment.fragment,
    fragmentName: AgencyTeamWithFragment.fragmentName,
  });

  const contacts = agency?.contacts || [];
  const numberOfAgents = contacts.length;
  const desktopLimit = 6;

  if (!complete || !agency || !contacts.length) {
    return null;
  }

  const renderAgentCard = ({
    contact,
  }: {
    contact: DeepPartial<ContactProfileCardFragment>;
  }) => (
    <ContactProfileCardWithFragment
      hideAppraisalCta={hideAppraisalCta}
      agencyId={agency.agencyId}
      agentIdV2={contact?.agentIdV2 || ''}
      contactId={contact?.contactId || 0}
      isVertical
      isShortStats
      hideOffice
      onViewProfile={onAgentViewProfile}
      onCall={onAgentCall}
      onCallReveal={onAgentCallReveal}
      onGetAppraisal={onAgentGetAppraisal}
      onOpenEnquiryForm={onAgentOpenEnquiryForm}
      onEnquirySubmit={onAgentEnquirySubmit}
      onEnquirySubmitSuccess={onAgentEnquirySubmitSuccess}
      onEnquirySubmitError={onAgentEnquirySubmitError}
      onEnquiryFinish={onAgentEnquiryFinish}
      appraisalEntryPoint="Agency Profile - Our Team"
    />
  );

  const typeSafeContacts = contacts.filter(arrayFilterNotEmpty);

  const uniqueContacts = uniqueByKey<DeepPartial<ContactProfileCardFragment>>(
    typeSafeContacts,
    'agentIdV2',
  );

  return (
    <div data-testid="team">
      <h2 css={styles.title}>Our Team</h2>
      <div css={styles.gridWrapper}>
        <div css={styles.desktopGrid}>
          <ul css={styles.grid(mobilePadding)}>
            {uniqueContacts?.slice(0, desktopLimit)?.map((contact) => (
              <li key={contact?.agentIdV2} css={styles.gridItem}>
                {renderAgentCard({ contact })}
              </li>
            ))}
          </ul>
          {numberOfAgents > desktopLimit ? (
            <>
              <Button
                data-testid={`${agencyId}_expand-feedback-button`}
                css={styles.viewMore(isTeamExpanded)}
                type="button"
                appearance="outline"
                onClick={() => {
                  setIsTeamExpanded(!isTeamExpanded);
                  onViewMore();
                }}
                aria-expanded={isTeamExpanded}
                aria-controls={`${agencyId}_id`}
                aria-disabled={isTeamExpanded}
                disabled={isTeamExpanded}
                id={`${agencyId}_labelId`}
              >
                {isTeamExpanded ? 'View less agents' : 'View more agents'}
              </Button>
              <div
                id={`${agencyId}_id`}
                role="region"
                aria-labelledby={`${agencyId}_label-id`}
                css={styles.gridAdditionalWrapper}
              >
                <AnimatePresence>
                  {isTeamExpanded && (
                    <motion.div
                      initial={styles.animationSettings.collapsed}
                      animate={styles.animationSettings.open}
                      exit={styles.animationSettings.collapsed}
                      transition={styles.animationSettings.transition}
                    >
                      <ul css={styles.grid(mobilePadding)}>
                        {uniqueContacts?.slice(desktopLimit)?.map((contact) => (
                          <li key={contact?.agentIdV2} css={styles.gridItem}>
                            {renderAgentCard({ contact })}
                          </li>
                        ))}
                      </ul>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </>
          ) : null}
        </div>
        <div css={styles.mobileGrid}>
          <ul css={styles.grid(mobilePadding)}>
            {uniqueContacts?.map((contact) => (
              <li key={contact?.agentIdV2} css={styles.gridItem}>
                {renderAgentCard({ contact })}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

AgencyTeamWithFragment.fragment = FRAGMENT;
AgencyTeamWithFragment.fragmentName = FRAGMENT_NAME;

export default AgencyTeamWithFragment;
