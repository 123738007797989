import React, { SyntheticEvent } from 'react';

import { Button } from '@domain-group/fe-blueprint-ui/button';

import * as style from './end-form-button-group.style';

interface EndFormButtonGroupProps {
  pillLabel: string;
  close: () => void;
  handleSubmit?: (event: SyntheticEvent) => void;
}

const EndFormButtonGroup = ({
  pillLabel,
  close = () => null,
  handleSubmit = () => null,
}: EndFormButtonGroupProps): JSX.Element => (
  <div css={style.pillModalwrapper}>
    <div css={style.buttonGroupWrapper}>
      <Button
        onClick={close}
        appearance="muted"
        css={style.cancelButtonStyleOverride}
      >
        Cancel <span css={style.srOnly}> filtering by {pillLabel}</span>
      </Button>
      <Button type="submit" onClick={handleSubmit}>
        See Results
      </Button>
    </div>
  </div>
);

export default EndFormButtonGroup;
