import React, { useState } from 'react';
import { SortIcon, InfoIcon } from '@domain-group/fe-co-icon';
import { Modal } from '@domain-group/fe-blueprint-ui/modal';
import { srOnly } from '@domain-group/fe-brary';
import SortByModal from './sort-by-modal';
import { SelectOption } from '../search-filter-bar/filters-bar/pill-forms';
import * as style from './sort-by.styles';
import {
  SORT_OPTIONS_AGENCIES,
  SORT_OPTIONS_AGENTS,
} from '../search-filter-bar/enums';

interface SortByProps {
  isAgency: boolean;
  selectedSortBy: string;
  setSortBy: (sortBy: string) => void;
  viewSortByTooltip?: () => void;
}

const SortBy = ({
  isAgency,
  selectedSortBy,
  setSortBy,
  viewSortByTooltip,
}: SortByProps): JSX.Element => {
  const [sortByModalActive, setSortByModalActive] = useState(false);
  const [seen, setSeen] = useState(false);

  const toggleSortByModal = () => {
    if (!sortByModalActive && !seen) {
      // Mixpanel track view sort by modal
      viewSortByTooltip?.();

      setSeen(true);
    }

    setSortByModalActive(!sortByModalActive);
  };

  const SORT_OPTIONS = isAgency ? SORT_OPTIONS_AGENCIES : SORT_OPTIONS_AGENTS;

  return (
    <div css={style.sortByWrap}>
      {sortByModalActive && (
        <Modal closeCallback={toggleSortByModal}>
          <SortByModal closeCallback={toggleSortByModal} />
        </Modal>
      )}
      <div css={style.sortBy}>
        <div css={style.sortByIcon}>
          <SortIcon size="small" />
        </div>
        <span css={style.sortByTitle}>Sort by:</span>
        <SelectOption
          instanceId="sortby"
          label="Sort order"
          labelHidden
          current={SORT_OPTIONS.find(
            (option) => option.value === selectedSortBy,
          )}
          handleChange={(option) => setSortBy(option?.value || '')}
          options={SORT_OPTIONS}
          placeholder="Featured"
        />
        <button
          css={style.sortByButton}
          type="button"
          onClick={toggleSortByModal}
          data-testid="search-header__sort-by-information"
          title="More information"
        >
          <InfoIcon size="small" />
          <span css={srOnly}>More information</span>
        </button>
      </div>
    </div>
  );
};

export default SortBy;
