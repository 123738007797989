import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@domain-group/fe-blueprint-ui/form';
import { srOnly } from '@domain-group/fe-brary';
import btoa from 'btoa-lite';

import * as styles from './fields.style';

const RadioFields = ({
  fieldName,
  onBlur,
  onChange,
  options,
  selected,
  title,
  hiddenTitle,
  wrapperStyles,
}) => (
  <div
    role="group"
    aria-labelledby={`group_label_${btoa(title)}`}
    css={wrapperStyles}
  >
    <p id={`group_label_${btoa(title)}`} css={hiddenTitle ? srOnly : ''}>
      {title}
    </p>
    {options.map((option) => {
      const handleChange = (event) => {
        onChange(fieldName, event.currentTarget.value);
      };

      const handleBlur = () => {
        onBlur(fieldName, true);
      };

      const isSelected = selected === option.value;

      return (
        <div css={styles.checkboxField} key={option.value}>
          <Radio
            name={fieldName}
            id={option.value}
            value={option.value}
            onChange={handleChange}
            onBlur={handleBlur}
            labelContent={option.label}
            checked={isSelected}
          />
        </div>
      );
    })}
  </div>
);

RadioFields.defaultProps = {
  selected: undefined,
  hiddenTitle: true,
  wrapperStyles: undefined,
};

RadioFields.propTypes = {
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selected: PropTypes.string,
  title: PropTypes.string.isRequired,
  hiddenTitle: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  wrapperStyles: PropTypes.any,
};

export default RadioFields;
