import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from '@domain-group/fe-blueprint-ui/button';
import { PhoneIcon, EmailIcon, ProfileIcon } from '@domain-group/fe-co-icon';
import { Message } from '@domain-group/fe-co-messages';
import { Loader } from '@domain-group/fe-blueprint-ui/loader';
import { TextField, RadioField } from '../form-fields';
import { INTENT_OPTIONS } from './constants';

import * as styles from './form.style';

const PHONE_REGEX_PATTERN =
  '^\\({0,1}((0|\\+61)(2|4|3|7|8)){0,1}\\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$';
const PHONE_REGEX = new RegExp(PHONE_REGEX_PATTERN);

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string()
    .email('Email address should be valid')
    .required('Email address is required'),
  phone: Yup.string().matches(PHONE_REGEX, 'Phone number is not valid'),
  intent: Yup.string(),
  message: Yup.string(),
  postcode: Yup.string()
    .required('Postcode is required')
    .matches(/^[0-9]+$/, 'Postcode should be valid')
    .min(4, 'Postcode should be valid')
    .max(4, 'Postcode should be valid'),
});

export type Details = {
  firstName: string;
  lastName: string;
  email: string;
  postcode: string;
  phone?: string;
  intent?: string;
  message?: string;
};

type FormProps = {
  details?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    postcode?: string;
    phone?: string;
    intent?: string;
    message?: string;
  };
  showIntentOptions?: boolean;
  onSubmit: (submitValues: Details) => void;
  submitting?: boolean;
  submissionError?: boolean;
};

const Form = ({
  details = {},
  showIntentOptions = false,
  onSubmit,
  submitting = false,
  submissionError = false,
}: FormProps): JSX.Element => {
  const initialValues = {
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    intent: '',
    message: '',
    postcode: '',
    ...details,
  };

  const {
    values,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    isInitialValid: () => validationSchema.isValidSync(initialValues),
    // validateOnMount: true,
    // enableReinitialize: true,
    onSubmit,
  });

  return (
    <form onSubmit={handleSubmit} css={styles.form} data-testid="enquiry-form">
      {submitting && (
        <div css={styles.submittingLoader}>
          <Loader />
        </div>
      )}
      <fieldset css={styles.fieldset} disabled={submitting}>
        <div
          role="group"
          aria-labelledby="group_label_1"
          css={styles.formGroup}
        >
          <p id="group_label_1" css={styles.intro}>
            Contact details
          </p>
          <TextField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            fieldName="firstName"
            value={values.firstName}
            label="First name"
            required
            errors={touched.firstName ? errors.firstName : null}
            icon={<ProfileIcon />}
          />
          <TextField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            fieldName="lastName"
            value={values.lastName}
            label="Last name"
            required
            errors={touched.lastName ? errors.lastName : null}
            icon={<ProfileIcon />}
          />
          <TextField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            fieldName="phone"
            value={values.phone || ''}
            label="Phone"
            title="We only accept valid Australian phone numbers"
            pattern={PHONE_REGEX_PATTERN}
            errors={touched.phone ? errors.phone : null}
            type="tel"
            icon={<PhoneIcon />}
          />
          <TextField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            fieldName="email"
            value={values.email}
            label="Email address"
            required
            type="email"
            errors={touched.email ? errors.email : null}
            icon={<EmailIcon />}
          />
        </div>
        <div role="group" aria-labelledby="group_label_2">
          <p id="group_label_2" css={styles.intro}>
            Your enquiry
          </p>
          {showIntentOptions && (
            <RadioField
              fieldName="intent"
              options={INTENT_OPTIONS}
              onBlur={setFieldTouched}
              onChange={setFieldValue}
              selected={values.intent}
              title="What would you like information about?"
              hiddenTitle
              wrapperStyles={styles.radioGroup}
            />
          )}
          <TextField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            fieldName="message"
            placeholder="What can we help you with?"
            value={values.message}
            label="Your message"
            errors={touched.message ? errors.message : null}
            multiline
            rows={4}
          />
          <TextField
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            fieldName="postcode"
            value={values.postcode}
            label="Your postcode"
            required
            errors={touched.postcode ? errors.postcode : null}
          />
        </div>
        {submissionError && (
          <Message type="error" alignContent="left">
            Something went wrong, please try again later.
          </Message>
        )}
        <Button type="submit" disabled={!isValid} css={styles.button}>
          Send enquiry
        </Button>
      </fieldset>
    </form>
  );
};

export default Form;
