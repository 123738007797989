import getConfig from 'next/config';
import { Breadcrumb } from '.';
import { AgencySearchQuery } from '../../../generated/graphql';

const { publicRuntimeConfig } = getConfig();

const mapSearchResultsBreadcrumbs = ({
  isAgent,
  suburbs,
}: {
  isAgent: boolean;
  suburbs?: AgencySearchQuery['locationProfiles'];
}): Breadcrumb[] => {
  if (!suburbs?.length) {
    return [];
  }

  // Note: Breadcrumbs use "Title Case"
  return [
    {
      title: isAgent ? 'Real Estate Agents' : 'Real Estate Agencies',
      url: `${publicRuntimeConfig.BASE_URL}/real-estate-agents/`,
    },
    {
      title:
        suburbs.length === 1
          ? (suburbs[0]?.suburbName ?? 'Search Area')
          : 'Multiple Suburbs',
      url: '', // not an active link, does not need a url
    },
  ];
};

export default mapSearchResultsBreadcrumbs;
